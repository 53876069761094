import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/styles.css';
import App from './App';
import './css/responsive.css';
import Router from "./Router"

ReactDOM.render(<Router />, document.getElementById("root"))






// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
