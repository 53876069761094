import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    body: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        width: '100%',
    },
    list: {
        listStyleType: 'none',
        fontSize: '5vw',
        color: '#212121',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));


const LeftView = () => {
    const classes = useStyles();

    return (
        <div className={classes.body}>
            <ul className={classes.list}>
                <li>Where is YouTube dislikes?</li>
                <li>Ебал отца Камалы Харрис</li>
                <li>Freut mich, Sie kennenzulernen!</li>
            </ul>
        </div>
    );
}


export default LeftView;