import React from 'react';
import './Header.css';
import logo from '../img/logo.png'
import { Link } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';

function Header() {
  return (
    <header className="header_area">
        <div className="main-menu">
            <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/" className="navbar-brand">
              <Typography  variant="h5" component="h1" id="navbar-id">
                  Brody Portfolio
              </Typography>
            </Link>
            <div className="mr-auto"></div>
            <Link to="/">
              <HomeIcon fontSize="large" id="navbar-icon" />
              </Link>
            </nav>

        </div>
    </header>
  );
}

export default Header;
