import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    body: {
        height: '100%'
    },
    root: {
        width: '100%',
    },
    text: {
        fontSize: theme.typography.pxToRem(150),
        fontWeight: theme.typography.fontWeightRegular,
        color: '#212121',
        flexBasis: '33.33%',
        flexShrink: 0,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
}));


const FunView = () => {
    const classes = useStyles();

    return (
        <div className={classes.body}>
            <p className={classes.text}>Хуй</p>
        </div>
    );
}


export default FunView;