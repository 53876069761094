import React from 'react';
import './Banner.css';

function Banner() {
  return (
    <main className="site-main">
            <section className="site-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 site-title">
                            <div className="site-title-wrapper">
                                <h3 className="title-text">Hey</h3>
                                <h1 className="title-text text-uppercase">I am Dima</h1>
                                <h4 className="title-text text-uppercase">Software Developer</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 site-title">
                            <div className="site-buttons">
                                    <a href="https://drive.google.com/file/d/1xe3yd488VZO4_szoySZeajZ9ZjNmbDGS/view?usp=sharing" target="_blank">
                                        <button type="button" className="btn button secondary-button text-uppercase">Get cv</button>
                                    </a>
                            </div>
                        </div>

                        {/*<div className="col-lg-2 col-md-1 col-sm-12"/>*/}
                        {/*<div className="col-lg-2 col-md-4 col-sm-12 banner-image">*/}
                        {/*    <img src={bannerImage} alt="banner-img" className="img-fluid" id=""/>*/}
                        {/*</div>*/}
                        {/*<div className="col-lg-2 col-md-1 col-sm-12"/>*/}
                    </div>
                </div>
            </section>
            </main>
  );
}

export default Banner;
