import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Container from '@material-ui/core/Container';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './MediaCard.css';
import {Link} from "react-router-dom"

const useStyles = makeStyles({
    root: {
        maxWidth: 280,
        position: 'relative',

    },
    data: {
        width: 300,
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    media: {
        height: 0,
        paddingTop: '178%'
    },
    typo: {
        color: "#333"
    },
    card: {
        width: 50,
        height: 50
    },

    icon: {
        height: 0,
        paddingTop: '100%'
    },
    overlay: {
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        color: '#fff',
    }
});

const MediaCard = (props) => {
    const classes = useStyles();

    const app = props.app

    const name = app.tag

    const link = '/app/' + name

    let isDarkNeeded = app.isDarkNeeded === false || app.isDarkNeeded !== undefined

    return (
        <div>

            <Card className={classes.root} id={isDarkNeeded ? "img-root-dark" : "img-root"} variant="outlined">
                <CardActionArea>
                    <Link to={{
                        pathname: link,
                        state: {app}
                    }}>
                        <CardMedia
                            className={classes.media}
                            image={app.cover}
                            title="Android App"
                        />
                    </Link>
                </CardActionArea>
            </Card>

            <div className={classes.data} id={isDarkNeeded ? "iconRootDark" : "iconRoot"}>
                <div className="container">
                    <div className="row">
                        <Card className={classes.card} style={{borderRadius: 16}}>
                            <CardMedia
                                className={classes.icon}
                                image={app.icon}/>
                        </Card>
                        <div className="col">
                            <Typography variant="h5" component="h2">
                                {app.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {app.desc}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MediaCard
