import React from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom"

import App from "./App"
import AppView from "./AppView/AppView"
import GuideView from "./GuideView/GuideView"
import FunView from "./FunView/FunView"
import LeftView from "./LeftView/LeftView"

const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={App} exact />
      <Route path="/app/:name" component={AppView} />
      <Route path="/guides" component={GuideView} />
      <Route path="/zxc" component={FunView} />
      <Route path="/rules" component={LeftView} />
    </Switch>
  </BrowserRouter>
)

export default AppRouter
