import React from 'react';
import Header from './Header/Header';
import Banner from './Banner/Banner';
import AppsGrid from './AppsGrid/AppsGrid';


function App() {
  return (
    <div>
      <Header />
      <Banner />
      <AppsGrid />
    </div>
  );
}

export default App;
