import React, {useState, useCallback} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Card from '../Card/MediaCard'
import './AppsGrid.css';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import { Link } from "react-router-dom"
import DeleteIcon from '@material-ui/icons/Delete';
import GitHubIcon from "@material-ui/icons/GitHub";


const AppsGrid = (props) => {

    const [state, setState] = useState([]);


    if (state === undefined || state.length === 0) {
        fetch('https://api.npoint.io/687aaa1eb9058b142899')
            .then(res => res.json())
            .then(data => setState(data));
    }


    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        text: {
            width: '100%',
            margin: '32px auto 150px auto',
            textAlign: 'center'
        }

    }));


    const classes = useStyles();


    return (
        <div>
            <Typography variant="h4" component="h1" className={classes.text} id="label_text">
                Android Projects
            </Typography>
            <div className={classes.root} id="gridMain">

                <Grid container spacing={1} justify="space-evenly">
                    {state === undefined || state.length === 0 ? <div/> : state.apps.map(app => {
                        return (
                            <Grid item xs={12} sm={6} md={4} key={app.tag}>
                                <Card app={app} className="gridItem"/>
                            </Grid>
                        )
                    })}

                </Grid>
            </div>
            <div className="container">
                <div className="row"  id="row-git">
                    <div className="col-sm-0 col-md-1 col-lg-3 col-xl-3"/>
                    <div className="col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        {state.gits === undefined ? <div/> : <div>
                            <List>
                                {
                                    state.gits.map((value) =>
                                        <ListItem button component="a" target="_blank"  href={value.gitUrl}>
                                            <ListItemAvatar>
                                                <Avatar alt={value.name} src={value.icon} variant="square" >
                                                    <FolderIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={value.name}
                                                secondary={value.desc}
                                            />
                                            {/*<ListItemSecondaryAction>*/}
                                            {/*    <a href={value.gitUrl} target="_blank">*/}
                                            {/*        <button type="button" className="btn btn-dark">*/}
                                                        <GitHubIcon/>
                                                        {/*&nbsp;Code*/}
                                            {/*        </button>*/}
                                            {/*    </a>*/}
                                            {/*</ListItemSecondaryAction>*/}
                                        </ListItem>
                                    )
                                }
                            </List>
                        </div>}
                    </div>
                    <div className="col-sm-0 col-md-2 col-lg-3 col-xl-3"/>
                </div>
            </div>
            <div className="container" id="footer-apps"/>
            {/*<div className="flag_container">*/}
            {/*    <Link to="/rules">*/}
            {/*        <img src="https://upload.wikimedia.org/wikipedia/commons/4/48/Gay_Pride_Flag.svg" className="dream_flag"/>*/}
            {/*    </Link>      */}
            {/*</div>*/}
        </div>
    )


}

export default AppsGrid
