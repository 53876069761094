import React, {useState} from 'react';
import Header from '../Header/Header';
import Typography from '@material-ui/core/Typography';
import PhotoGallery from '../Gallery/Gallery';
import './AppView.css';
import Card from '@material-ui/core/Card';
import GetAppIcon from '@material-ui/icons/GetApp';
import googlePlay from "../img/icons/googleplay.png"
import GitHubIcon from '@material-ui/icons/GitHub';


const AppView = (props) => {

    const [state, setState] = useState(props.location.state === undefined ? undefined : props.location.state.app);

    if(state === undefined){
        fetch('https://api.npoint.io/687aaa1eb9058b142899')
            .then(res => res.json())
            .then(data => setState(data.apps.find(currentValue => currentValue.tag === props.match.params.name)));
    }

    return (
        <div>
            <Header/>
            <div id="appView_gallery">
                { state === undefined ? <div/> :
                    <div className="row">
                        <div className="col col-sm-1 col-md-2 col-lg-2 col-xl-3"/>
                        <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6">

                            <div className="row" id="view-margins">

                                <Card id="card-root" style={{borderRadius: 16}}>
                                    <img src={state.icon} id="card-media"/>

                                </Card>
                                <div className="col">
                                    <Typography variant="h4" component="h2">
                                        {state.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {state.desc}
                                    </Typography>

                                </div>

                                {
                                    state.apkUrl === undefined ? <div/> :
                                        <div className="column">
                                            <Typography id="view-row">
                                                <a href={state.apkUrl}>
                                                    <button type="button" class="btn btn-light">
                                                        <GetAppIcon/>
                                                    </button>
                                                </a>
                                            </Typography>
                                            <span class="badge badge-dark" id="btn-badge">Apk</span>
                                        </div>

                                }
                                {
                                    state.playStoreUrl === undefined ? <div/> :
                                        <div className="column">
                                            <Typography id="view-row">
                                                <a href={state.playStoreUrl}>
                                                    <img src={googlePlay} id="button-play-market" />
                                                </a>
                                            </Typography>
                                        </div>
                                }
                            </div>

                            {
                                state.fullDesc === undefined ? <div/> :
                                    <Typography variant="body1" color="#333" component="p" id="view-desc">
                                        <div dangerouslySetInnerHTML={{__html: state.fullDesc}} />
                                    </Typography>
                            }


                            <div id="view-gallery-div">
                                <Card id="view-gallery">

                                    <PhotoGallery images={state.images}/>
                                </Card>
                            </div>

                            {
                                state.gitUrl === undefined ? <div/> :
                                    <Typography>
                                        <a href={state.gitUrl} target="_blank">
                                            <button type="button" className="btn btn-dark">
                                                <GitHubIcon/> &nbsp;Source Code
                                            </button>
                                        </a>
                                    </Typography>
                            }
                            {
                                state.playStoreUrl === undefined ? <div/> :
                                    <Typography>
                                        <a href={state.playStoreUrl} target="_blank">
                                            <button type="button" className="btn btn-dark" >
                                                <img src={googlePlay} id="button-play-market" /> &nbsp;Play Market
                                            </button>
                                        </a>
                                    </Typography>
                            }
                        </div>
                        <div className="col col-sm-1 col-md-2 col-lg-2 col-xl-3">
                            
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}

export default AppView;
